import { useCallback, useEffect } from "react";
import { useNewTest } from "../../hooks/useNewTest";
import { useState } from "react";
import { useRealtime } from "../../hooks/useRealtime";
import { getQuery } from "../../utils";
import MobileWaitingDesktop from "./mobile/MobileWaitingDesktop";
import { useTranslation } from "react-i18next";

export const TestMobile = () => {
  const { t, i18n } = useTranslation();
  const [waiting, setWaiting] = useState(true);
  const [test, setTest] = useState(null);
  const [state, setState] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const query = getQuery();
  const token = parseInt(query.token);
  const { readWorkflow } = useNewTest();
  const realtime = useRealtime("mobile");

  const processFeedback = useCallback(
    ({ type, test, state }) => {
      if (type === "refresh") {
        setTest(test);
        setWorkflow(readWorkflow(test));
      }
      setState(state);
      setWaiting(false);
    },
    [readWorkflow]
  );

  useEffect(() => {
    i18n.changeLanguage(query.lang)
    realtime.subscribe(token, () => {
      realtime.listener(token, "feedback", processFeedback);

      realtime.trigger(token, "connection", {});
    });
  }, [processFeedback, token, realtime]);

  const answer = (data) => {
    if (waiting) {
      // ignore message, potential duplicated call
      return;
    }
    setWaiting(true);
    realtime.trigger(test.token, "answer", { answer: data });
  };

  return (
    <div className="article-card">
      {state && test && workflow ? (
        <workflow.MobileComponent
          test={test}
          answer={answer}
          state={state}
          waiting={waiting}
        />
      ) : (
        <MobileWaitingDesktop />
      )}
    </div>
  );
};
