import MobileWaitingDesktop from "./mobile/MobileWaitingDesktop";
import DesktopAgree from "./desktop/DesktopAgree";
import DesktopAge from "./desktop/DesktopAge";
import DesktopGender from "./desktop/DesktopGender";
import DesktopKnowSpecs from "./desktop/DesktopKnowSpecs";
import DesktopSpecs from "./desktop/DesktopSpecs";
import DesktopSpecsInfo from "./desktop/DesktopSpecsInfo";
import DesktopHaveSpecs from "./desktop/DesktopHaveSpecs";
import DesktopWearing from "./desktop/DesktopWearing";
import DesktopConnection from "./desktop/DesktopConnection";
import MobileConnection from "./mobile/MobileConnection";
import DesktopCountFeet from "./desktop/DesktopCountFeet";
import MobileCountFeet from "./mobile/MobileCountFeet";
import DesktopPlaceObject from "./desktop/DesktopPlaceObject";
import MobilePlaceObject from "./mobile/MobilePlaceObject";
import DesktopCoverLeft from "./desktop/DesktopCoverLeft";
import MobileCoverLeft from "./mobile/MobileCoverLeft";
import DesktopCoverRight from "./desktop/DesktopCoverRight";
import MobileCoverRight from "./mobile/MobileCoverRight";
import Mobile4Circles from "./mobile/Mobile4Circles";
import Desktop4CirclesRight from "./desktop/Desktop4CirclesRight";
import Desktop4CirclesLeft from "./desktop/Desktop4CirclesLeft";
import { storageTest } from "../../hooks/useNewTest";
import {
  Custom2Circles,
  Custom4Circles,
  CustomRectangle,
  defaultAnswer,
  defaultMoveNext,
  processCirclesAnswer,
  processRectangleAnswer,
} from "./DynamicQuestion.script";
import DesktopRectangleRight from "./desktop/DesktopRectangleRight";
import MobileRectangle from "./mobile/MobileRectangle";
import Desktop2CirclesRight from "./desktop/Desktop2CirclesRight";
import Mobile2Circles from "./mobile/Mobile2Circles";
import Desktop2CirclesLeft from "./desktop/Desktop2CirclesLeft";
import DesktopCoverLeftGlassON from "./desktop/DesktopCoverLeftGlassON";
import MobileCoverLeftGlassON from "./mobile/MobileCoverLeftGlassON";
import MobileCoverRightGlassON from "./mobile/MobileCoverRightGlassON";
import DesktopCoverRightGlassON from "./desktop/DesktopCoverRightGlassON";
import DesktopThankyou from "./desktop/DesktopThankyou";
import MobileThankyou from "./mobile/MobileThankyou";
import DesktopNearOrFarObjects from "./desktop/DesktopNearOrFarObjects";
import MobileNearOrFarObjects from "./mobile/MobileNearOrFarObjects";
import DesktopWhichDevice from "./desktop/DesktopWhichDevice";
import MobileWhichDevice from "./mobile/MobileWhichDevice";
import Desktop4CirclesRightGlassON from "./desktop/Desktop4CirclesRightGlassON";
import Desktop4CirclesLeftGlassON from "./desktop/Desktop4CirclesLeftGlassON";

// TODO: remove all localStorage, calculate the result iter steps
const DynamicQuestion = {
  agree: {
    DesktopComponent: DesktopAgree,
    MobileComponent: MobileWaitingDesktop,
    answer: defaultAnswer,
  },
  age: {
    storageOnBackend: true,
    DesktopComponent: DesktopAge,
    MobileComponent: MobileWaitingDesktop,
    answer: defaultAnswer,
    log: (data) => {
      const { age, dob } = data.result.value;
      return {
        test: "Age",
        results: [`Age - ${age}`, `DOB - ${dob}`],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  gender: {
    storageOnBackend: true,
    DesktopComponent: DesktopGender,
    MobileComponent: MobileWaitingDesktop,
    answer: async (test, answer) => {
      // default as female
      let gender = "female";
      let steps = Math.round(304.8 / 25);

      if (answer === "male") {
        gender = "male";
        steps = Math.round(304.8 / 28.2);
      }

      test.steps[test.workflow].answers.push({ value: answer });
      test.steps[test.workflow].result = {
        finalizedAt: new Date(),
        value: { steps, gender },
      };

      await storageTest(test.token, test);

      return true;
    },
    log: (data) => {
      const { gender, steps } = data.result.value;
      return {
        test: "Gender",
        results: [`Gender - ${gender}`, `Number Of Steps - ${steps}`],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  knowSpecs: {
    storageOnBackend: true,
    DesktopComponent: DesktopKnowSpecs,
    MobileComponent: MobileWaitingDesktop,
    answer: defaultAnswer,
    log: (data) => {
      return {
        test: "Know Specs",
        results: [
          `Know Specs? ${data.result.value.knowSpecs}`,
          `Ratio - ${data.result.value.ratio}`,
        ],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  specs: {
    storageOnBackend: true,
    DesktopComponent: DesktopSpecs,
    MobileComponent: MobileWaitingDesktop,
    conditional: (test) =>
      test.steps?.knowSpecs?.result?.value?.knowSpecs === "yes",
    answer: defaultAnswer,
    log: (data) => {
      const { oldSphOD, oldSphOS, oldCylOD, oldCylOS, oldAxisOD, oldAxisOS } =
        data.result.value;
      return {
        test: "Specs",
        results: [
          `oldSphOD - ${oldSphOD}`,
          `oldSphOS - ${oldSphOS}`,
          `oldCylOD - ${oldCylOD}`,
          `oldCylOS - ${oldCylOS}`,
          `oldAxisOD - ${oldAxisOD}`,
          `oldAxisOS - ${oldAxisOS}`,
        ],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  specsInfo: {
    storageOnBackend: true,
    DesktopComponent: DesktopSpecsInfo,
    MobileComponent: MobileWaitingDesktop,
    conditional: (test) =>
      test.steps?.knowSpecs?.result?.value?.knowSpecs === "yes",
    answer: defaultAnswer,
    log: (data) => {
      const { visionQuality, lensCondition, lastExam } = data.result.value;
      return {
        test: "Specs Info",
        results: [
          `visionQuality - ${visionQuality}`,
          `lensCondition - ${lensCondition}`,
          `lastExam - ${lastExam}`,
        ],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  haveSpecs: {
    storageOnBackend: true,
    DesktopComponent: DesktopHaveSpecs,
    MobileComponent: MobileWaitingDesktop,
    conditional: (test) =>
      test.steps?.knowSpecs?.result?.value?.knowSpecs === "yes",
    answer: defaultAnswer,
    log: (data) => {
      const { haveSpecs } = data.result.value;
      return {
        test: "Have Specs",
        results: [`Have Specs? ${haveSpecs}`],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  wearing: {
    DesktopComponent: DesktopWearing,
    MobileComponent: MobileWaitingDesktop,
    answer: defaultMoveNext,
  },
  connection: {
    DesktopComponent: DesktopConnection,
    MobileComponent: MobileConnection,
    answer: defaultMoveNext,
  },
  countFeet: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCountFeet,
    MobileComponent: MobileCountFeet,
    load: (test) => {
      const countFeet = test?.steps?.gender?.result?.value?.steps;
      test.steps[test.workflow] = {
        state: { countFeet },
        answers: [],
      };
    },
    answer: defaultMoveNext,
  },
  placeObject: {
    requireMobilePresence: true,
    DesktopComponent: DesktopPlaceObject,
    MobileComponent: MobilePlaceObject,
    answer: defaultMoveNext,
  },
  nearOrFarObjects: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: DesktopNearOrFarObjects,
    MobileComponent: MobileNearOrFarObjects,
    answer: (test, answer) => {
      return defaultAnswer(test, { Answer1a: answer });
    },
    log: (data) => {
      const { Answer1a } = data.result.value;
      return {
        test: "Answer 1",
        results: [`Answer1a - ${Answer1a}`],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  whichDevice: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: DesktopWhichDevice,
    MobileComponent: MobileWhichDevice,
    answer: (test, answer) => {
      return defaultAnswer(test, { Answer1b: answer });
    },
    log: (data) => {
      const { Answer1b } = data.result.value;
      return {
        test: "Answer 2",
        results: [`Answer1b - ${Answer1b}`],
        answers: [],
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test4CirclesCoverLeft: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverLeft,
    MobileComponent: MobileCoverLeft,
    answer: defaultMoveNext,
  },
  test4CirclesRight: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop4CirclesRight,
    MobileComponent: Mobile4Circles,
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAscOD", 4);
    },
    ...Custom4Circles,
    log: (data) => {
      const { VAscOD } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "4C - Right",
        results: [`VAscOD - ${VAscOD}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test4CirclesCoverRight: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverRight,
    MobileComponent: MobileCoverRight,
    answer: defaultMoveNext,
  },
  test4CirclesLeft: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop4CirclesLeft,
    MobileComponent: Mobile4Circles,
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAscOS", 4);
    },
    ...Custom4Circles,
    log: (data) => {
      const { VAscOS } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "4C - Left",
        results: [`VAscOS - ${VAscOS}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  testRectCoverLeft: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverLeft,
    MobileComponent: MobileCoverLeft,
    answer: defaultMoveNext,
  },
  testRectRight: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: DesktopRectangleRight,
    MobileComponent: MobileRectangle,
    answer: async (test, answer) => {
      return processRectangleAnswer(test, answer, "PrimeDrPOVOD");
    },
    ...CustomRectangle,
    log: (data) => {
      const { PrimeDrPOVOD, wrong_percent } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        const { axis_x, axis_y } = answer.value.state;
        return { 'Answer?': answerCorrect, "AxisX - ": axis_x, "AxisY - ": axis_y };
      };
      return {
        test: "Axis - Right",
        results: [`PrimeDrPOVOD - ${PrimeDrPOVOD}`, `Wrong Percentage - ${Math.round(wrong_percent * 100)}%` ],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  testRectCoverRight: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverRight,
    MobileComponent: MobileCoverRight,
    answer: defaultMoveNext,
  },
  testRectLeft: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: DesktopRectangleRight,
    MobileComponent: MobileRectangle,
    answer: async (test, answer) => {
      return processRectangleAnswer(test, answer, "PrimeDrPOVOS");
    },
    ...CustomRectangle,
    log: (data) => {
      const { PrimeDrPOVOS, wrong_percent } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        const { axis_x, axis_y } = answer.value.state;
        return { 'Answer?': answerCorrect, "AxisX - ": axis_x, "AxisY - ": axis_y };
      };
      return {
        test: "Axis - Left",
        results: [`PrimeDrPOVOS - ${PrimeDrPOVOS}`, `Wrong Percentage - ${Math.round(wrong_percent * 100)}%` ],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test2CirclesCoverLeft: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverLeft,
    MobileComponent: MobileCoverLeft,
    answer: defaultMoveNext,
  },
  test2CirclesRight: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop2CirclesRight,
    MobileComponent: Mobile2Circles,
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAaOD", 2);
    },
    ...Custom2Circles,
    log: (data) => {
      const { VAaOD } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "2C - Right",
        results: [`VAaOD - ${VAaOD}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test2CirclesCoverRight: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverRight,
    MobileComponent: MobileCoverRight,
    answer: defaultMoveNext,
  },
  test2CirclesLeft: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop2CirclesLeft,
    MobileComponent: Mobile2Circles,
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAaOS", 2);
    },
    ...Custom2Circles,
    log: (data) => {
      const { VAaOS } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "2C - Left",
        results: [`VAaOS - ${VAaOS}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test4CirclesCoverLeftGlassON: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverLeftGlassON,
    MobileComponent: MobileCoverLeftGlassON,
    conditional: (test) =>
      test.steps?.haveSpecs?.result?.value?.haveSpecs === "yes",
    answer: defaultMoveNext,
  },
  test4CirclesRightGlassON: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop4CirclesRightGlassON,
    MobileComponent: Mobile4Circles,
    conditional: (test) =>
      test.steps?.haveSpecs?.result?.value?.haveSpecs === "yes",
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAccOD", 4);
    },
    ...Custom4Circles,
    log: (data) => {
      const { VAccOD } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "4C - Right (Glass ON)",
        results: [`VAccOD - ${VAccOD}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  test4CirclesCoverRightGlassON: {
    requireMobilePresence: true,
    DesktopComponent: DesktopCoverRightGlassON,
    MobileComponent: MobileCoverRightGlassON,
    conditional: (test) =>
      test.steps?.haveSpecs?.result?.value?.haveSpecs === "yes",
    answer: defaultMoveNext,
  },
  test4CirclesLeftGlassON: {
    requireMobilePresence: true,
    storageOnBackend: true,
    DesktopComponent: Desktop4CirclesLeftGlassON,
    MobileComponent: Mobile4Circles,
    conditional: (test) =>
      test.steps?.haveSpecs?.result?.value?.haveSpecs === "yes",
    answer: async (test, answer) => {
      return processCirclesAnswer(test, answer, "VAccOS", 4);
    },
    ...Custom4Circles,
    log: (data) => {
      const { VAccOS } = data.result.value;
      const normalizeAnswers = (answer) => {
        const answerCorrect = answer.value.answerCorrect
          ? "Correct"
          : "Incorrect";
        return { 'Answer?': answerCorrect, "Current VA": answer.value.va };
      };
      return {
        test: "4C - Left (Glass ON)",
        results: [`VAccOS - ${VAccOS}`],
        answers: data.answers.map(normalizeAnswers),
        finalizedAt: data.result.finalizedAt,
      };
    },
  },
  thankyou: {
    isFinalStep: true, // indication to finish test here
    DesktopComponent: DesktopThankyou,
    MobileComponent: MobileThankyou,
    answer: null,
  },
};

export default DynamicQuestion;
