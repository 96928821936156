import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSpecsDropdown } from "../../../hooks/useSpecDropdown";

export default function DesktopSpecs({ answer }) {
  const { t } = useTranslation();

  const [sr, setSr] = useState("0");
  const [cr, setCr] = useState("0");
  const [ar, setAr] = useState("0");
  const [sl, setSl] = useState("0");
  const [cl, setCl] = useState("0");
  const [al, setAl] = useState("0");
  const [spherePlus, setSpherePlus] = useState(false);

  const sphereDropdown = useSpecsDropdown(-10, 10, 0.25);
  const cylinderDropdown = useSpecsDropdown(-6, 0, 0.25);
  const axisDropdown = useSpecsDropdown(0, 180, null);

  useEffect(() => {
    if (spherePlus === true) {
      SaveSpecs();
    }
  });

  const questionText = t("Please enter your glasses prescription");

  // const [BCPlaceHolder, setBCPlaceHolder] = useState("Base Curve")

  const SaveSpecs = () => {
    if (sr == "" && sl == "") {
      alert(t("both sphere values must be entered"));
    } else if (sr == 0 && cr == 0 && sl == 0 && cl == 0) {
      alert(t("both sphere values must be entered"));
    } else if (sr.length === "") {
      alert(t("value for right eye sphere must be entered"));
    } else if (sr.length === "") {
      alert(t("value for right eye sphere must be entered"));
    } else if (sl.length === "") {
      alert(t("value for left eye sphere must be entered"));
    } else if (cr > 0 || cl > 0) {
      alert(t("Cylinder values must be negative numbers"));
    } else if (
      ar.length > 3 ||
      al.length > 3 ||
      ar > 180 ||
      al > 180 ||
      ar < 0 ||
      al < 0
    ) {
      alert(t("Axis values have up to 3 digits (from 0 to 180)"));
    } else if (cr < 0 && (ar.length === 0 || ar == 0 || ar == "")) {
      alert(
        t(
          "AXIS MISSING for right eye (must be between 0 and 180 if there is a cylinder value)"
        )
      );
    } else if (cl < 0 && (al.length === 0 || al == 0 || al == "")) {
      alert(
        t(
          "AXIS MISSING for left eye (must be between 0 and 180 if there is a cylinder value)"
        )
      );
    } else if ((sr > 0 || sl > 0) && spherePlus === false) {
      Swal.fire({
        title: t("Please confirm plus and not minus sphere?"),
        showCancelButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        icon: "info",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setSpherePlus(true);
          console.log(" depois de alterarspherePlus", spherePlus);
        }
      });
    } else {
      // TODO: complexity logic, review later
      let oldSphOD = sr || 0;
      let oldSphOS = sl || 0;
      let oldCylOD = cr;
      let oldCylOS = cl;
      let oldAxisOD = ar;
      let oldAxisOS = al;

      if (cr.length === 0 || cr === 0) {
        oldCylOD = 0;
      }
      if (cl.length === 0 || cl === 0) {
        oldCylOS = 0;
      }
      if (ar.length === 0) {
        oldAxisOD = 0;
      }
      if (al.length === 0) {
        oldAxisOS = 0;
      }
      if (cr.length === 0 || cr === 0) {
        oldAxisOD = 0;
      }
      if (cl.length === 0 || cl === 0) {
        oldAxisOS = 0;
      }

      answer({ oldSphOD, oldSphOS, oldCylOD, oldCylOS, oldAxisOD, oldAxisOS });
    }
  };

  return (
    <TestWrapper image={`../images/test/005.jpg`}>
      <h2 className="mb-5">{questionText}</h2>

      <Form>
        <div className="specs-table">
          <h2 className="mr-4">{t("Right (OD)")}</h2>
          <div className="form-item-wrapper">
            <div className="form-item">
              <h5>{t("Sphere")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setSr(v.target.value);
                }}
              >
                {sphereDropdown}
              </Form.Group>
            </div>

            <div className="form-item">
              <h5>{t("Cylinder")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setCr(v.target.value);
                }}
              >
                {cylinderDropdown}
              </Form.Group>
            </div>

            <div className="form-item">
              <h5>{t("AXIS")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setAr(v.target.value);
                }}
              >
                {axisDropdown}
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="specs-table">
          <h2 className="mr-4">{t("Left (OS)")}</h2>
          <div className="form-item-wrapper">
            <div className="form-item">
              <h5>{t("Sphere")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setSl(v.target.value);
                }}
              >
                {sphereDropdown}
              </Form.Group>
            </div>

            <div className="form-item">
              <h5>{t("Cylinder")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setCl(v.target.value);
                }}
              >
                {cylinderDropdown}
              </Form.Group>
            </div>

            <div className="form-item">
              <h5>{t("AXIS")}</h5>
              <Form.Group
                style={{ width: "90%" }}
                onChange={(v) => {
                  setAl(v.target.value);
                }}
              >
                {axisDropdown}
              </Form.Group>
            </div>
          </div>
        </div>
      </Form>

      <div className="nav-view">
        <div onClick={SaveSpecs} className="nav-button">
          <div>{t("Continue")}</div>
        </div>
      </div>
    </TestWrapper>
  );
}
